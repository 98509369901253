.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.headerPage {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
  overflow: auto;
  padding: 16px 24px;
}

.transfer {
  margin-bottom: 24px;
  width: 40vw;
}

.tokenInfo {
  margin-bottom: 24px;
  width: 40vw;
}

.tokenId {
  font: var(--typography-body);
  margin-bottom: 8px;
}

.polygonScanButton {
  width: 100%;
}

.txDetailsTitle {
  font: var(--typography-body);
  margin-bottom: 8px;
}

.txDetails {
  font-size: 12px;
  line-height: 1.5;
  white-space: pre-wrap;
  width: 100%;
  word-wrap: break-word;
}

.footer {
  margin: 16px 24px;
}

.retryButton {
  width: 100%;
}

@media screen and (max-width: 820px) {
  .transfer {
    margin-bottom: 24px;
    width: auto;
  }
  
  .tokenInfo {
    margin-bottom: 24px;
    width: auto;
  }
}