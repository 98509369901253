.containerMobile {
  display: none;
}

.containerDesktop {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.content {
  padding: 24px;
  gap: 32px;
}

.networks {
  margin-top: 3rem;
}
.networks h3 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.chain {
  font-weight: normal !important;
}

.avatarDiv {
  display: flex;
  flex-direction: row;
}

.editProfilePic {
  margin-left: 1rem;
}

.editProfilePic img {
  width: 18px !important;
}

.button {
  margin-top: 3rem;
  background-color: transparent !important;
  color: #71a5f4 !important;
  padding: 0 !important;
  width: auto;
}

.profileImage {
  display: flex;
  height: 120px;
  width: 120px;
  min-height: 120px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.profile {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
}

.profile p {
  color: var(--alt14-color);
  margin-bottom: 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

#value p {
  color: var(--text-color-accent);
}

.editButton {
  display: flex;
  background: var(--input-bg-color);
  border-radius: 4px;
  padding: 6px;
  margin-left: 14px;
  border: 0;
  color: var(--text-color);
}
.editButton:hover:after {
  content: 'Edit profile';

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 80px;
  height: 24px;
  margin-left: 25px;
  margin-top: 5px;
  background: var(--alt5-color);
  border-radius: 4px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--body-color);
}
.editButton:focus {
  outline: none;
  box-shadow: none;
}

.logout {
  width: 180px;
  min-width: 180px;
  border: 0;
  background: inherit;
  cursor: pointer;
  margin-top: 40px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--primary-color);
}
.logout:hover {
  opacity: 0.5;
}

.allAccountInfo {
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.logOutButton {
  display: flex;
  height: 20vh;
}

.tabs {
  width: 100% !important;
}

@media screen and (max-width: 820px) {
  .containerDesktop {
    display: none;
  }

  .containerMobile {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .button {
    margin-top: auto;
  }

  .content {
    display: flex;
    padding: 24px;
    gap: 32px;
    flex-direction: column;
    height: 100%;
  }

  .walletButton {
    background-color: #ff2600;
    margin-top: auto;
  }
}
