.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  color: var(--grey-50);
  font: var(--typography-body);
  font-weight: 600;
  margin-bottom: 16px;
  text-align: center;
}

.chainList {
  margin-bottom: 16px;
}

.chainListItem {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 0;
}

.chainListItem:not(:last-child) {
  border-bottom: 1px solid var(--grey-70);
}

.chainNameContainer {
  display: flex;
  align-items: center;
}

.chainIcon {
  width: 56px;
  display: flex;
  align-items: center;
}

.chainName {
  color: var(--grey-50);
  font: var(--typography-h2);
}

.check {
  height: 20px;
  width: 20px;
}