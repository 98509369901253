.header {
  display: flex;
  gap: 24px;
}

.header > svg {
  margin-top: 2px;
  color: var(--orange);
}

.label {
  margin: 20px 20px 20px 40px;
  color: var(--text-color);
}
