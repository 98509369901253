.image {
  object-fit: contain;
  height: 500px;
  width: 100%;
  cursor: pointer;
  align-items: center;
}

@media screen and (max-width: 820px) {
  .image {
    object-fit: contain;
    height: 220px;
    border-radius: 8px;
    cursor: pointer;
  }
}
