.container {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.to {
  height: 48px;
  width: 48px;
}

.avatar {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-color: var(--grey-90);
  border-radius: 24px;
  border: 4px solid var(--grey-95);
}

.address {
  color: var(--grey-0);
  font-size: 14px;
  font-weight: 700;
}

.arrow {
  margin: 0 6px;
}
