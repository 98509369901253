.networks {
  margin-top: 2rem;
}
.networks h3 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.chain {
  font-weight: normal !important;
  color: var(--text-color) !important;
}

.button {
  margin-top: 3rem;
  background-color: transparent !important;
  color: #71a5f4 !important;
  padding: 0 !important;
  width: auto;
}
