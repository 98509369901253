.container {
  display: flex;
}

.container > img {
  margin-right: 5px;
  width: 26px;
  min-width: 26px;
  height: 26px;
}
