.container {
  height: 112px;
  position: relative;

  color: var(--grey-0);
  background: var(--grey-85);
  border: 1px solid var(--grey-85);
  border-radius: 6px;
  padding: 8px 32px 8px 16px;
}

.word {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.clear {
  position: absolute;
  top: 2px;
  right: 0;
}
