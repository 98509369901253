.container {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  margin-top: 2rem;
}

label {
  font-weight: bold;
}

.textField {
  width: 100%;
  word-break: break-all;
  margin-top: 2rem;

  background: var(--grey-85);
  border: 1px solid var(--grey-85);
  border-radius: 6px;
  padding: 8px 32px 8px 16px;
}

.duplicate {
  display: flex;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

@media screen and (max-width: 820px) {
  .container {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
  }

  .textField {
    height: 112px;
    width: 100%;

    background: var(--grey-85);
    border: 1px solid var(--grey-85);
    border-radius: 6px;
    padding: 8px 32px 8px 16px;

    word-break: break-all;
  }

  .duplicate {
    display: flex;
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  label {
    font-weight: normal;
  }
}
