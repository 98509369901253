.container {
  max-width: 290px;
  background: var(--alt-bg-color);
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}

.container:last-child {
  margin-bottom: 0;
}

.thumbnail {
  width: 250px;
  height: 250px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.borderRadius {
  display: none;
}

/* If the screen size is 820px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .container {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: var(--grey-90);
    border-radius: 8px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .thumbnail {
    display: none;
  }

  .borderRadius {
    display: inline;
    border-radius: 4px;
  }
}
