.loading {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;
  width: 100%;
  height: 400px;
  border-radius: 4px;
}

.borderRadius {
  border-radius: 4px;
}

.fullHeight {
  min-height: auto !important;
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
