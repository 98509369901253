.header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin: 0 32px;
}

.logo {
  display: flex;
  margin: 0;
  padding: 0;
  line-height: 0;
  height: auto;
  width: 150px;
}

.chainInfo {
  color: var(--grey-10) !important;
  font: var(--typography-body) !important;
  font-weight: bold !important;
}

.logo img {
  max-width: 100%;
}

.userInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.button {
  margin-right: 2rem;
}

.buttonDisconnect {
  background-color: #ff2600;
}

.info {
  display: flex;
  flex-direction: row;
  max-height: 16px;
}

.info > div:not(:last-child) {
  display: flex;
  margin-right: 24px;
}

.button__address {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info > div > span:first-child,
span:nth-child(2),
span:nth-child(3) {
  color: var(--text-color-secondary);
  display: inline-block;
  font: var(--typography-body);
  margin-right: 10px;
}

.info > div > span:last-child {
  color: var(--text-color-accent);
  display: inline-block;
  font: var(--typography-button);
  font-size: 16px;
  font-weight: 600;
}

.info > div:last-child {
  display: flex;
}

.dialogHeader {
  padding: 24px;
}

.content {
  max-height: 750px;
  overflow-y: scroll;
}

@media screen and (max-width: 820px) {
  .header {
    display: none;
  }
}
