.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 24px;
}

.containerHeader {
  display: flex;
  flex-direction: column;
  height: 48px;
  width: 100%;
  margin-top: 2rem;
}

.containerSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containerList {
  height: 100%;
  overflow-y: scroll;
  margin-top: 25px;
  margin-bottom: 25px;
}

.bodyHeight {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
}
.mainItems {
  display: flex;
  flex-direction: column;
  height: 90vh;
}
.list {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  column-gap: 25px;
  row-gap: 15px;
}

.list > li {
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
}

.selectedElement {
  opacity: 70%;
}

.selectedItem {
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 37px !important;
  text-align: end;
}

.checkmark {
  display: inline-block;
  transform: rotate(45deg);
  height: 25px;
  width: 12px;
  border-bottom: 7px solid #78b13f;
  border-right: 7px solid #78b13f;
}

.containerButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 10vh;
  margin-top: auto;
  padding-right: 3rem;
}

.cancelButton {
  display: flex;
  width: auto;
}

.continueButton {
  display: flex;
  width: auto;
}

.previousButton {
  display: flex;
  width: auto;
  margin-right: 10px;
}

.network {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-right: 3rem;
  position: relative;
}

.network > label {
  margin-bottom: 1rem;
}

.amount > label {
  margin-bottom: 1rem;
}

.network > input {
  align-items: center;
  background-color: var(--grey-85);
  border-radius: 8px;
  color: var(--grey-0);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 16px 16px;
  width: 100%;
}

.network > svg {
  cursor: pointer;
  position: absolute;
  bottom: 24px;
  right: 12px;
  z-index: 100;
}

.amount {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.cryptoInfo {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.cryptoBalance {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
