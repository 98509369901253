.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

@media screen and (max-width: 820px) {
  .wrapper {
    max-width: 100%;
  }
}
