.message {
  font: var(--typography-body);
  color: var(--grey-10);
  line-height: 2;
  word-wrap: break-word;
}

.message > h3 {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 10px;
}
