.modal {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;
}

.titleText {
  font: var(--typography-h2);
  color: var(--grey-40);
}

.messageText {
  font: var(--typography-body);
  color: var(--grey-40);
  max-height: 50vh;
  overflow: auto;
  width: 100%;
  word-wrap: break-word;
}

.button {
  width: 100%;
}
