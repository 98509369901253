.container {
  display: none;
}

.qr {
  margin-left: auto;
  width: 22px;
  height: 22px;
}

.backIcon {
  transform: rotate(90deg);
}

.backIcon:hover {
  opacity: 0.8;
}

.chainInfo {
  display: flex;
  flex: 1;
}

/* If the screen size is 820px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .container {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 0 24px;
    width: 100%;
  }
}
