.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.containerHeader {
  display: flex;
  flex-direction: column;
}

.containerContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  padding-bottom: 16px;
}

.containerUserInfo {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 24px;
  gap: 16px;
  margin-top: 16px;
}

.historyContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.historyHeader {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 24px;
}

.historyTitle {
  font: var(--typography-h2);
  color: var(--text-color-accent);
}

.empty {
  padding: 0 24px;
}
