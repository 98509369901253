.container {
  display: none;
}

.containerDesktop {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 24px;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 10vh;
  margin-top: auto;
  padding-right: 3rem;
}

.cancelButton {
  display: flex;
  width: auto;
}

.continueButton {
  display: flex;
  width: auto;
}

.previousButton {
  display: flex;
  width: auto;
  margin-right: 10px;
}

.headerPage {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  height: 90vh;
  justify-content: space-between;
}

.payReview {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.description {
  font: var(--typography-body);
  margin-bottom: 32px;
}

.feeDetails {
  margin-bottom: 44px;
}

.feeDetailsTitle {
  font: var(--typography-h2);
  margin-bottom: 20px;
}

.paymentMethod {
  border-bottom: 2px solid var(--grey-85);
  margin-bottom: 16px;
  padding-bottom: 10px;
}

.paymentMethodTitle {
  font: var(--typography-h2);
  margin-bottom: 24px;
}

.paymentMethodContents {
  align-items: center;
  display: flex;
}

.paymentMethodIcon {
  margin-right: 8px;
}

.balance {
  color: var(--grey-50);
  display: flex;
  align-items: center;
}

.balanceStatusIcon {
  margin-left: 5px;
  vertical-align: middle;
}

.errorContainer {
  margin: 8px 0;
}

.errorIcon,
.errorMessage {
  vertical-align: middle;
}

.transferButton {
  width: 100%;
}

.spacer {
  border-right: 1px solid #bbb;
  opacity: 25%;
}

@media screen and (max-width: 820px) {
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .containerDesktop {
    display: none;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
  }

  .balance {
    color: var(--grey-50);
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
