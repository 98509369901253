.containerWallet {
  display: none;
}

.containerDesktop {
  background-color: var(--grey-95);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.containerHeader {
  display: none;
}

.containerHeader_desktop {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  padding-right: 24px;
}

.header {
  padding-top: 32px;
  padding-left: 26px;
}

.content {
  background-color: var(--grey-95);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 24px;
  height: 100%;
  gap: 24px;
}

.footer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-self: center;
}

@media screen and (max-width: 820px) {
  .containerWallet {
    background-color: var(--grey-90);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .containerHeader {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    padding-bottom: 8px;
    justify-content: space-between;
    padding-right: 24px;
  }

  .containerHeader_desktop {
    display: none;
  }

  .containerDesktop {
    display: none;
  }

  .header {
    display: none;
  }

  .footer {
    align-self: auto;
  }
}
