.container {
  height: 40px;

  border-bottom: 2px solid var(--grey-85);
  margin-top: 16px;
  cursor: pointer;
}

.arrow {
  transform: rotate(-90deg);
  opacity: 0.4;
}

.label {
  color: var(--text-color-accent);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
