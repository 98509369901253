.container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.container > li {
  border-radius: 8px;
  flex: 1;
  overflow: hidden;
}

.container > li > a {
  background-color: var(--grey-85);
  cursor: pointer;
  display: block;
  font: var(--typography-h2);
  height: 80px;
  padding: 16px;
}

.container > li > a.active {
  background-color: var(--accent-blue);
}
