.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headerPage {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.scrollableContainer {
  flex: 1 0;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 16px 24px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.transferTypeSwitch {
  margin-bottom: 28px;
}

.network {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  position: relative;
}

.network > label {
  color: var(--grey-40);
  font: var(--typography-body);
  margin-bottom: 4px;
}

.network > input {
  align-items: center;
  background-color: var(--grey-85);
  border-radius: 8px;
  color: var(--grey-0);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 16px 16px;
  width: 100%;
}

.network > svg {
  cursor: pointer;
  position: absolute;
  bottom: 24px;
  right: 12px;
  z-index: 100;
}

.amount {
  margin: 36px 0;
}

.recipientType {
  margin-bottom: 16px;
}

.recipient {
  flex: 1;
}

.chooseFriend {
  display: flex;
  flex-direction: column;
  position: relative;
}

.chooseFriend > input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--grey-85);
  border-radius: 8px;
  padding: 16px 24px 16px 16px;
  cursor: pointer;
}

.chooseFriend > svg {
  cursor: pointer;
  position: absolute;
  bottom: 24px;
  right: 12px;
  z-index: 100;
}

.errorContainer {
  margin: 8px 0;
}

.errorIcon,
.errorMessage {
  vertical-align: middle;
}

.footer {
  margin-top: 24px;
}

.continueButton {
  width: 100%;
}

@media screen and (max-width: 820px) {
  .footer {
    margin-top: 0;
  }

  .container {
    width: 100%;
  }
}
