.modal {
  height: 30vh;
  width: 50vw;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  color: var(--grey-50);
  font: var(--typography-body-accent);
  text-align: center;
}

.contents {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}


@media screen and (max-width: 820px) {
  .modal {
    height: 100vh;
    width: 100vw;
  }
}