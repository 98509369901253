.header {
  flex-shrink: 0;
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
  align-items: baseline;
}

.header h2 {
  font-weight: bold;
  font-size: x-large;
}
