.container {
  align-items: center;
  background: #000;
  background: radial-gradient(circle, var(--accent-blue) 0%, #000 50%);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 500px;
}

.container > img {
  display: block;
  margin: 50px;
}

.audio {
  bottom: 8px;
  height: 40px;
  left: 8px;
  position: absolute;
  right: 8px;
}

.audio > audio {
  display: block;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 820px) {
  .container > img {
    height: 120px;
  }

  .container {
    height: auto;
    width: auto;
  }
}
