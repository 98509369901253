.userInfo {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}

.avatarContainer {
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 32px;
}

.editIcon {
  position: absolute;
  top: 0;
  left: 64px;
}

.avatar {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.nickName {
  display: none;
}

.nickNameDesktop {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.nickNameDesktop p {
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  font-family: Montserrat, sans-serif;
  color: var(--text-color);
  justify-content: space-between;
}

.nickNameDesktop span {
  margin-left: 16px;
  font-family: Montserrat, sans-serif;
  color: var(--text-color);
  font-weight: bold;
  align-items: flex-end;
}

.creator {
  margin-top: 4px;
  width: fit-content;
  padding: 4px 10px;
  border-radius: 4px;
  font: var(--typography-label-subtext);
  background-color: var(--primary-60);
}

.cursor {
  cursor: pointer;
}

@media screen and (max-width: 820px) {
  .userInfo {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: relative;
  }

  .nickName {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    font: var(--typography-h3);
    color: var(--text-color-accent);
  }

  .nickNameDesktop {
    display: none;
  }
}
