.container {
  background-color: var(--grey-95);
  flex: 1;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  text-align: left;
  overflow: hidden;
}

.container__info {
  align-self: flex-start;
  float: left;
  width: 30%;
  margin-left: 5%;
  margin-top: 10%;
}

.container__info article {
  margin: 25px 0;
}

.createLabel {
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  color: var(--text-color-accent);
  margin-bottom: 8px;
}

.errorField {
  height: 48px;
}

/* If the screen size is 820px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .container {
    flex-direction: column;
  }

  .container__info {
    display: contents;
    width: 100%;
    margin: 0;
    height: 100%;
  }

  .container__info article {
    width: 100%;
  }
}
