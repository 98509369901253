.wrapper > div {
  display: flex;
  border-radius: inherit;
}

.clearable {
  flex: 1;
}

.wrapper > div > button {
  align-items: center;
  background-color: transparent;
  border: none;
  margin-left: 5px;
}

.wrapper > div > button:disabled {
  display: none;
}

.wrapper > ul {
  z-index: 1;
  width: 100%;
  max-height: 100px;
  overflow-y: scroll;
  color: #14161c;
  background-color: #eee;
  padding: 0;
  list-style: none;
}

.wrapper > ul > li {
  padding: 5px 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wrapper > ul > li:global(.highlighted) {
  background-color: #bde4ff;
}
