.item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-bottom: 2px solid transparent;
  border-bottom-color: var(--input-bg-color);
  padding-bottom: 18px;
  color: var(--alt10-color);
  font-size: 16px;
}

.item:not(.itemDisabled) {
  cursor: pointer;
  border-bottom-color: var(--primary-color);
}

.itemSelected {
  color: var(--text-color-accent);
}

.check {
  margin-right: 10px;
}

.step {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  padding-top: 3px;
  margin-right: 8px;
  opacity: 0.4;
  background-color: var(--alt8-color);
  color: var(--text-color-accent);
  text-align: center;
}
.stepSelected {
  opacity: 1;
  background-color: var(--primary-color);
}

.item circle {
  fill: transparent;
}

.item path {
  stroke: var(--alt10-color);
}
