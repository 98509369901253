.modal {
  width: 50%;
  align-items: center;
}

.mobile {
  display: none;
}

.desktop {
  display: flex;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
}

.modalTitle {
  text-align: center;
  color: var(--grey-50);
}

.menu {
  margin-top: 28px;
  display: flex;
  flex-direction: column;
}

.menu > li {
  height: 36px;
  margin-top: 16px;
  cursor: pointer;
  border-bottom: 2px solid var(--grey-85);
  color: var(--text-color-accent);
}

@media screen and (max-width: 820px){
  .modal {
    width: 100%;
    align-items: flex-end;
  }

  .mobile {
    display: flex;
  }
  
  .desktop {
    display: none;
  }
}