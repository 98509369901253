.containerWallet {
  display: none;
}

.containerDesktop {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 24px;
}

.headerPage {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px 24px;
  gap: 16px;
}

.chooseFriend {
  display: flex;
  flex-direction: column;
  position: relative;
}

.chooseFriend > input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--grey-85);
  border-radius: 8px;
  padding: 8px 24px 8px 16px;
  cursor: pointer;
}

.chooseFriend > svg {
  cursor: pointer;
  position: absolute;
  bottom: 16px;
  right: 12px;
}

.footer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.recipientType {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-shrink: 0;
}

.recipientAddress {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tradeWith {
  font: var(--typography-h2);
}

.collectibles {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  cursor: pointer;
}

.addCollectible {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid var(--grey-90);
  border-radius: 8px;
}

.addCollectibleText {
  font: var(--typography-h2);
  color: var(--text-color-accent);
}

.containerFooter {
  margin-top: auto;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 820px) {
  .containerWallet {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .containerDesktop {
    display: none;
  }
}
