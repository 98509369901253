.container {
  display: none;
}

.action {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.6;
  outline: none;
}

.action:hover,
.action:active {
  opacity: 1;
}

.actionImg {
  width: 24px;
  margin-bottom: 6px;
}

.actionTitle {
  color: var(--text-color-accent);
  font-size: 10px;
  line-height: 12px;
}

.active {
  opacity: 1;
}

/* If the screen size is 820px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .container {
    margin-top: auto;
    padding: 12px 0;
    display: flex;
    justify-content: space-around;
    background-color: var(--grey-90);
    border-top: 1px solid var(--grey-95);
  }
}
