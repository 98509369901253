.video {
  cursor: pointer;
  height: auto;
  object-fit: cover;
  width: 100%;
  height: 500px;
}

@media screen and (max-width: 820px) {
  .video {
    height: 220px;
  }
}
