.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.image {
  width: 85px;
  height: 90px;
  margin-bottom: 20px;
}

.message {
  text-align: center;
  white-space: pre-line;
  font-size: 16px;
  color: var(--grey-60);
  margin-bottom: 30px;
}

.buttonRetry {
  width: 180px;
  text-transform: none;
  font-weight: 600;
  font-size: 16px;
  color: var(--text-color-accent);
}
