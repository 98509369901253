.container {
  display: none;
}

.containerDesktop {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 24px;
}

.containerHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.containerContent {
  display: flex;
  flex-direction: row;
  height: 90vh;
  padding: 50px 24px 24px 24px;
  gap: 24px;
}

.containerFooter {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  padding: 0 24px 16px;
}

.tradeWith {
  font: var(--typography-h2);
}

.insufficientFundsMessage,
.totalPrice {
  margin-bottom: 12px;
}

.reviewNFT {
  display: flex;
  flex-direction: column;
  width: 20vw;
}

.reviewUser {
  display: flex;
  flex-direction: column;
  width: 30vw;
}

.reviewPay {
  display: flex;
  flex-direction: column;
  width: 40vw;
  font: var(--typography-h2);
}

.text {
  padding: 0 24px;
  font-weight: normal;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.textFee {
  font-weight: normal;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.textPrice {
  color: var(--grey-0);
  font: var(--typography-body);
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.textResult {
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.payment {
  font-size: 20px;
  padding: 0 24px;
  font-weight: bold;
  margin-top: 2rem;
}

.balanceCoins {
  margin-top: 1rem;
  font: var(--typography-body);
  padding: 0 24px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.cardsSelector {
  margin-top: 1rem;
  font: var(--typography-body);
  padding: 0 24px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.symbol {
  font-size: 25px !important;
  font-weight: bold !important;
  line-height: 0px;
  align-self: center !important;
}

.price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 10vh;
  margin-top: auto;
  padding-right: 3rem;
}

.cancelButton {
  display: flex;
  width: auto;
}

.continueButton {
  display: flex;
  width: auto;
}

.previousButton {
  display: flex;
  width: auto;
  margin-right: 10px;
}

hr.solid {
  margin-left: 24px;
  margin-right: 24px;
  border-top: 1px solid #bbb;
}

.spacer {
  border-right: 1px solid #bbb;
  opacity: 25%;
}

.balanceValue {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.transactionType {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.transactionButton {
  margin: 0.5rem;
}

.title {
  color: var(--grey-50);
  font: var(--typography-body);
  font-weight: 600;
  margin-bottom: 16px;
  text-align: center;
}

.chainList {
  margin: 24px;
}

.chainListItem {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 0;
}

.chainListItem:not(:last-child) {
  border-bottom: 1px solid var(--grey-70);
}

.chainNameContainer {
  display: flex;
  align-items: center;
}

.chainIcon {
  width: 56px;
  display: flex;
  align-items: center;
}

.chainIcon img {
  height: 40px;
}

.chainName {
  color: var(--grey-50);
  font: var(--typography-h2);
}

.check {
  height: 20px;
  width: 20px;
}

.cardElement {
  width: 300px;
  background-color: #ffffff17;
  color: black;
  border-radius: 10px;
  padding: 10px;
}

.selectCardTitle {
  font-size: 20px;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: bold;
}

.buttonsPayCard {
  display: flex;
  flex-direction: row;
}

.confirmationCancelButton {
  padding: 10px;
  width: 50%;
}

@media screen and (max-width: 820px) {
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 24px;
    width: 100%;
  }

  .containerContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 24px;
    grid-gap: 24px;
    gap: 24px;
  }

  .containerDesktop {
    display: none;
  }
}
