.container {
  background-color: var(--grey-95);
  flex: 1;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  text-align: left;
  overflow: hidden;
}

.container__info {
  width: 30%;
  margin-left: 5%;
}

.header {
  margin: 24px 0;
}

.title {
  font: var(--typography-h1);
  color: var(--text-color-accent);
}

.subTitle {
  margin-top: 8px;
  color: var(--text-color);
}

.agreement {
  display: flex;
  gap: 10px;
  color: var(--text-color);
}

.agreementLink {
  text-decoration: underline;
}

.username,
.email {
  display: block;
  margin: 24px 0;
}

.container__info > button {
  margin-top: 30px;
}

/* If the screen size is 820px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .container {
    flex-direction: column;
  }

  .container__info {
    display: contents;
    width: 100%;
    margin: 0;
    height: 100%;
  }
}
