.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.loading {
  visibility: hidden;
}

.QRCodeCamera {
  width: 100%;
}
