.image {
  object-fit: cover;
}

.skeleton {
  line-height: unset !important;
}

.skeleton,
.image {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
