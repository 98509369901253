.container {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  gap: 16px;
}

.container > div:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.containerByDate {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 18px;
  border-bottom: 2px solid var(--grey-85);
  min-height: fit-content;
}

.date {
  color: var(--grey-60);
  font-size: 14px;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: fit-content;
}

.item {
  min-height: fit-content;
  background-color: var(--grey-90);
}
