.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--overlay-background-color);
}

.container {
  background-color: var(--grey-90);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  width: 500px;
  padding: 40px;
}

.closeButton {
  align-items: center;
  background-color: inherit;
  border: 0;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 15px;
  width: 30px;
  height: 30px;
}

.closeButton svg {
  /* fix misaligned icon */
  position: relative;
  top: 2px;
}

.closeButton:hover {
  opacity: 0.5;
  background-color: inherit;
}

@media screen and (max-width: 820px) {
  .overlay {
    max-width: 820px;
    align-items: flex-end;
  }

  .container {
    background-color: var(--grey-90);
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    position: relative;
    padding: 25px;
    width: 100%;
  }
}
