.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  padding: 24px;
}

.container > li {
  border-radius: 8px;
  flex: 1;
  overflow: hidden;
}

.container > li > a {
  background-color: var(--grey-85);
  cursor: pointer;
  display: block;
  font: var(--typography-h2);
  height: 80px;
  padding: 16px;
}

.container > li > a.active {
  background-color: var(--accent-blue);
}

.button {
  margin: 10px;
  width: 200px !important;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 70vh;
  align-items: center;
}

.bodyHeight {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 10vh;
  margin-top: auto;
  padding-right: 3rem;
}

.cancelButton {
  display: flex;
  width: auto;
}

.continueButton {
  display: flex;
  width: auto;
}
