.container {
  background-color: var(--grey-90);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  background-color: var(--grey-95);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px 16px 24px;
  height: 100%;
  margin-top: 16px;
}

.button {
  margin-top: auto;
}
