.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.container__header {
  display: flex;
  width: 100%;
  height: 10%;
}

.container__info {
  /* display: flex; */
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.containerHeader {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.collections {
  display: none;
}

.list {
  display: flex;
  overflow: scroll;
  flex-wrap: wrap;
  grid-gap: 32px;
  gap: 32px;
}

.tabs {
  margin-top: 0;
}

.infoMessage {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.filter {
  display: flex;
  gap: 24px;
}

.filter > .active {
  color: var(--text-color-accent);
  text-decoration: underline;
}

.pageArea {
  width: 100%;
}


/* If the screen size is 820px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .container {
    flex-direction: column;
  }

  .pageArea {
    display: none;
  }

  .container__info {
    flex-direction: column;
    width: 100%;
    margin: 0;
    height: 100%;
  }

  .container__header {
    display: none;
  }

  .collections {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    background: var(--grey-90);
  }

  .list {
    height: 100%;
    overflow-y: scroll;
    padding: 16px 24px;
    background-color: var(--grey-95);
    transition: .5s ease;
    display: inline;
  }
}
