.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px;
  justify-content: space-between;
  flex: 1;
  background-color: var(--grey-95);
}

.contents {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 24px;
}

.network {
  display: none;
}

.network > label {
  color: var(--grey-40);
  font: var(--typography-body);
  margin-bottom: 4px;
}

.network > input {
  align-items: center;
  background-color: var(--grey-85);
  border-radius: 8px;
  color: var(--grey-0);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 16px 16px;
  width: 100%;
}

.network > svg {
  cursor: pointer;
  position: absolute;
  bottom: 24px;
  right: 12px;
}

.balance {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.balanceRow {
  flex-shrink: 0;
  margin: 12px 0;
  display: flex;
  align-items: center;
  gap: 16px;
}

.balanceValue {
  color: var(--grey-0);
  font: var(--typography-h1);
}

.balance > label {
  color: var(--grey-40);
  font: var(--typography-body);
}

.info {
  margin-top: 32px;
  font: var(--typography-body);
}

.footer {
  flex: 0 0 auto;
}

.topUpButton {
  width: 100%;
}

.logo {
  height: 26px;
  width: 40px;
  object-fit: contain;
}

.cards {
  margin-top: 40px;
}

@media screen and (max-width: 820px) {
  .network {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    position: relative;
  }
}