.container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  text-align: center;
  overflow: hidden;
}

.container__info {
  min-width: 30%;
  max-width: 30%;
  margin-left: 5%;
  margin-right: 5%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.container__info img {
  margin-top: -100px;
  width: auto;
  height: 40%;
  float: left;
  margin-bottom: 2rem;
}

.container__info__titleContainer {
  display: flex;
  flex-direction: column;
}

.container__info__titleContainer__title {
  width: 100%;
  text-align: left;
  font-size: 3.7vh;
  line-height: 100%;
  font-weight: bold;
  color: white;
  margin-bottom: 1.5rem;
}

.container__info__titleContainer__subTitle {
  display: flex;
  width: 100%;
  text-align: left;
  margin-bottom: 3rem;
}

.container__info__buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.container__info__buttonContainer__logInButton {
  color: var(--accent-pale-blue);
}
.container__info__buttonContainer__logInButton:hover {
  opacity: 0.8;
}

.content {
  margin: 20px;
}
.content > p {
  font: var(--typography-body-additional);
  color: var(--text-color);
}
.content > h1 {
  font: var(--typography-h1);
  color: var(--text-color-accent);
  margin-bottom: 16px;
}
/* If the screen size is 820px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .container {
    flex-direction: column;
  }

  .container__info {
    display: contents;
    width: 100%;
    margin: 0;
    height: 100%;
  }

  .container__info img {
    margin-top: 150px;
    width: 172px;
    height: 134px;
    content: url('assets/images/beasyLogo@2x.png');
  }

  .container__info__titleContainer__title {
    text-align: center;
  }

  .container__info__titleContainer__subTitle {
    text-align: center;
  }
}
