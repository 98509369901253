.container {
  color: var(--text-color-accent);
  width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.container.gold {
  background: var(--accent-gold);
}
.container.carrot {
  background: var(--accent-carrot);
}
.container.purple {
  background: var(--primary-60);
}
.container.blue {
  background: var(--accent-blue);
}
.container.green {
  background: var(--accent-green);
}
.container.white {
  background: var(--grey-0);
  color: var(--grey-85);
}
.container.grey {
  background: var(--grey-80);
}
