.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;
  text-align: center;
}
.container h2 {
  font: var(--typography-h2);
}
.container h1 {
  font: var(--typography-h1);
  color: var(--text-color-accent);
}
.container div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
}
.container p {
  font: var(--typography-body);
}

.titleText {
  font: var(--typography-h2);
  color: var(--grey-50);
}
