.container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.switch {
  background-color: #a1a1a1;
  border-radius: 20px;
  height: 40px;
  margin: 0 8px;
  padding: 2px;
  transition: background-color 0.15s ease-in-out;
  width: 76px;
}

.container input:checked + .switch {
  background-color: #004cff;
}

.handle {
  align-items: center;
  background-color: #fff;
  border-radius: 18px;
  display: flex;
  height: 36px;
  justify-content: center;
  transition: transform 0.15s ease-in-out;
  width: 36px;
}

.container input:checked + .switch > .handle {
  /* 76px - 36px - 2 * 2px */
  transform: translateX(36px);
}

.label {
  user-select: none;
  color: var(--text-color-accent);
}
