/* TODO: Check layout*/
.container {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.title {
  color: var(--text-color-accent);
  margin-bottom: 8px;
}

.profiles {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.avatars {
  display: flex;
  margin-right: 12px;
}

.avatar {
  border-radius: 14px;
  height: 28px;
  width: 28px;
}

.addresses {
  color: var(--text-color);
  font-size: 14px;
}

.collectible {
  display: flex;
  align-items: center;
}

.thumbnail {
  height: 48px;
  width: 48px;
  border-radius: 24px;
  margin-right: 12px;
}

.collectibleName {
  color: var(--text-color-accent);
  font-size: 18px;
}
