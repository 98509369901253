.container {
  padding: 0 24px;
}

.title {
  margin-top: 16px;
  font: var(--typography-h1);
  color: var(--text-color-accent);
}

.subTitle {
  margin-top: 8px;
  font: var(--typography-body);
  color: var(--grey-60);
}

.subTitleInfo {
  font: var(--typography-body);
  color: var(--grey-60);
}
