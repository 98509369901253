.modal {
  margin-top: 25%;
  width: 50%;
  top: 0;
  border-radius: 15px !important;
  position: absolute;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;
}

.titleText {
  font: var(--typography-h2);
  color: var(--grey-50);
}

.messageText {
  font: var(--typography-body);
  color: var(--grey-50);
}

@media screen and (max-width: 820px) {
  .modal {
    position: relative;
    width: 100%;
    margin-top: 0;
    border-radius: 0px;
  }
}
