.modal {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  width: 100%;
}

.button:last-child {
  margin-top: 16px;
}

.loadFile {
  display: none;
}
