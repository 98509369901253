.box {
  background: #2f3542;
  border: 1px solid #2f3542;
  border-radius: 6px;
  color: #fff;
  line-height: 20px;
  padding: 10px 15px;
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
  min-height: var(--input-min-height);
}

.box:focus {
  outline: none;
  box-shadow: none;
}
