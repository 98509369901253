.wrapper > ul {
  list-style: none;
}

.wrapper > ul > li {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 200;
  color: #999;
}

.remove {
  margin-right: 0.5em;
  background-color: inherit;
  border: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  padding: 0;
  opacity: 0.8;

  position: relative;
  top: 3px;
}

.remove:hover {
  opacity: 1;
}
