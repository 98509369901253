.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.titleText {
  font: var(--typography-h2);
  color: var(--grey-50);
}