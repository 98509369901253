.wrapper {
  margin-top: 2rem;
}

.title {
  font-weight: bold;
}

.balance {
  display: flex;
  flex-direction: column;
}

.balanceBlock {
  overflow-y: scroll;
}

.balanceRow {
  margin: 12px 0;
  display: flex;
  gap: 16px;
}

.balanceValue {
  color: var(--grey-0);
  font: var(--typography-h1);
}

.coinName {
  display: flex;
  flex-direction: row;
}

.cards {
  display: flex;
  margin-top: 40px;
}

.info {
  margin-top: 32px;
  font: var(--typography-body);
}

.logo {
  height: 26px;
  width: 40px;
  object-fit: contain;
}