.container {
  height: 8px;
  width: 100%;
}

.bar {
  background-color: #004cff;
  height: 6px;
  overflow: hidden;
  width: 0;
}

.barInner {
  animation: progressBackground 2s linear 0s infinite;
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    ),
    #004cff;
  height: 6px;
  width: 100%;
}

@keyframes progressBackground {
  from {
    transform: translate(-200%);
  }
  to {
    transform: translate(200%);
  }
}
