.modal {
  height: 80vh;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 24px;
}

.containerHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  font: var(--typography-h2);
  color: var(--grey-50);
}

.list {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-right: 8px;
}

.list > li {
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
}
