.container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  text-align: center;
  overflow: hidden;
}

.container__info {
  align-self: flex-start;
  float: left;
  width: 30%;
  margin-left: 5%;
  margin-top: 10%;
}

.container__info__createLabel {
  font-size: 28px;
  line-height: 30px;
  font-weight: bold;
  color: var(--text-color-accent);
  margin-bottom: 8px;
  margin-top: 2rem;
}

.container__info article p {
  text-align: left;
  margin-bottom: 1rem;
}

.container__info fieldset {
  text-align: left;
  margin-top: 2rem;
}

.agreement {
  display: flex;
  gap: 10px;
}

.mnemonic {
  position: relative;
  height: 220px;
}

.mnemonic > textarea {
  height: 150px;
  width: 100%;

  color: var(--grey-50);
  background: var(--grey-85);
  border: 1px solid var(--grey-85);
  border-radius: 6px;
  padding: 8px 32px 8px 16px;
}

.mnemonic > p {
  font-size: 10px;
}

.paste {
  position: absolute;
  top: 140px;
  right: 4px;
}

.container__info__button {
  width: 100%;
}

.container__info__button__error {
  width: 100%;
  margin-top: 2rem;
}

/* If the screen size is 820px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .container {
    flex-direction: column;
  }

  .container__info {
    display: contents;
    width: 100%;
    margin: 0;
    height: 100%;
  }

  .container__info__createLabel {
    font-size: 24px;
    margin-top: 0;
  }

  .mnemonic > textarea {
    height: 112px;
  }

  .paste {
    top: 100px;
  }
}
