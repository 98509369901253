.splash {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.splash > img {
  width: 175px;
  height: 135px;
}
