.userInfo {
  display: flex;
  align-items: center;
  height: 64px;
  margin-right: 8px;
  cursor: pointer;
}

.avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 20px;
}

.avatar > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.nickName {
  margin-left: 16px;
  font: var(--typography-h3);
  color: var(--text-color-accent);
}

@media screen and (max-width: 820px) {
  .userInfo {
    border-bottom: 2px solid var(--grey-85);
  }
}
