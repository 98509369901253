.button {
  border: 0;
  border-radius: 6px;
  color: var(--text-color-accent);
  cursor: pointer;
  padding: 0 25px;
  transition: background-color 0.2s;
  text-align: center;
  width: 100%;
  height: var(--button-height);
  font: var(--typography-button-large);
}

.button:disabled,
.button:disabled:hover,
.button.primary:disabled,
.button.primary:disabled:hover {
  color: var(--grey-80);
  background-color: var(--grey-90);
}

.button.primary {
  background-color: var(--accent-blue);
}

.button.primary:hover {
  background-color: #0044e5;
}

.button.secondary {
  background-color: rgba(255, 255, 255, 0.16);
}

.button.secondary:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

.button.error {
  background-color: #ff2600;
}

.button.error:hover {
  background-color: #e02200;
}