.spinner {
  animation: spin 5s linear infinite;
  opacity: 0.5;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
