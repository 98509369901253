.container {
  display: flex;
  gap: 5px;
}

.containerIcon {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.containerIcon > div {
  width: fit-content;
}
