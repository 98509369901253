.container {
  margin-top: 2rem;
}

.container h1 {
  display: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.logo {
  display: none;
}

.version {
  display: none;
}

.menu {
  width: 100%;
}

@media screen and (max-width: 820px) {
  .container {
    margin-top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .content {
    padding: 32px 24px;
  }

  .container h1 {
    display: flex;
  }

  .version {
    margin-top: 24px;
    color: var(--grey-0);
  }

  .logo {
    display: flex;
    width: 100px;
  }
}
