.container {
  display: flex;
  width: 100%;
}

.container > img {
  margin: 0;
  margin-left: 20px;
  width: auto;
  height: 50px;
}

.backIcon {
  transform: rotate(90deg);
}

.backIcon:hover {
  opacity: 0.8;
}

/* If the screen size is 820px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .container > img {
    margin: 0 auto;
    height: 30px;
  }
}
