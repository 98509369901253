.light {
  mix-blend-mode: overlay;
}

.dodge {
  mix-blend-mode: color-dodge;
}

.multiply {
  mix-blend-mode: multiply;
}

.svgImage {
  width: 100%;
  height: 100%;
}
