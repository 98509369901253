.modal {
  height: 100%;
  width: 40%;
}

.container {
  padding-top: 60px;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
}

.uriContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-shrink: 0;
  align-items: center;
  padding: 24px;
  position: relative;
}

.uriInput {
  width: 100%;
  background-color: var(--grey-85);
  border-radius: 6px;
  padding: 10px 40px 10px 15px;
}

.paste {
  position: absolute;
  right: 28px;
  top: 28px;
}

.field {
  height: 70px;
  width: 100%;
}

@media screen and (max-width: 820px) {
  .modal {
    height: 100%;
    width: 100%;
  }
}
