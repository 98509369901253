.container {
  margin-top: 2rem;
}

.formFields {
  display: flex;
  margin-top: 32px;
  gap: 20px;
}

.problemTitle {
  color: var(--text-color-accent);
  font: var(--typography-h2);
  width: 100%;
}

.chooseProblem {
  display: flex;

  width: 100%;
  height: fit-content;
  flex-direction: column;
  position: relative;
}

.chooseProblem > label {
  margin-bottom: 4px;
}

.chooseProblem > input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--grey-85);
  border-radius: 8px;
  padding: 16px 24px 16px 16px;
  cursor: pointer;
}

.chooseProblem > svg {
  cursor: pointer;
  position: absolute;
  bottom: 24px;
  right: 12px;
  z-index: 100;
}

.fieldset {
  width: 100%;
  display: flex;
  height: fit-content;
  flex-direction: column;
}

.fieldset > label {
  margin-bottom: 4px;
}

.fieldset > textarea {
  height: 130px;
  width: 100%;
  background-color: var(--grey-85);
  border-radius: 8px;
  padding: 8px 16px;
  word-break: break-all;
}

.fieldset > input {
  width: 100%;
  background-color: var(--grey-85);
  border-radius: 8px;
  padding: 12px 16px;
  word-break: break-all;
}

.sendRequestButton {
  margin-top: 5rem;
  width: 20vw;
}

.pageTitle {
  display: none;
}

@media screen and (max-width: 820px) {
  .container {
    height: 100%;
    width: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }

  .content {
    height: 100%;
    padding: 0 24px 16px;
    display: flex;
    flex-direction: column;
  }

  .formFields {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    gap: 20px;
  }

  .problemTitle {
    margin-top: 24px;
    color: var(--text-color-accent);
    font: var(--typography-h2);
  }

  .chooseProblem {
    margin-top: 32px;
    display: flex;
    width: auto;
    flex-direction: column;
    position: relative;
  }

  .chooseProblem > label {
    margin-bottom: 4px;
  }

  .chooseProblem > input {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--grey-85);
    border-radius: 8px;
    padding: 16px 24px 16px 16px;
    cursor: pointer;
  }

  .chooseProblem > svg {
    cursor: pointer;
    position: absolute;
    bottom: 24px;
    right: 12px;
    z-index: 100;
  }

  .email {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
  }

  .email > label {
    margin-bottom: 4px;
  }

  .email > input {
    width: 100%;
    background-color: var(--grey-85);
    border-radius: 8px;
    padding: 12px 16px;
    word-break: break-all;
  }

  .descriptionText {
    margin-top: 32px;
    display: flex;
    width: auto;
    margin-left: 0;
    flex-direction: column;
  }

  .descriptionText > label {
    margin-bottom: 4px;
  }

  .descriptionText > textarea {
    height: 130px;
    width: 100%;
    background-color: var(--grey-85);
    border-radius: 8px;
    padding: 8px 16px;
    word-break: break-all;
  }

  .sendRequestButton {
    margin-top: auto;
    width: auto;
  }

  .pageTitle {
    display: flex;
  }
}
