.container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: auto auto auto;
  column-gap: 50px;
  row-gap: 200px;
  padding: 24px;
  overflow-y: scroll;
}

.item {
  flex-shrink: 0;
  margin-bottom: 20px;
  padding-bottom: 32px;
}

.date {
  display: none;
}

.dateSkeleton {
  display: inline-block;
  width: 100px;
}

.itemSkeleton {
  height: 36px;
  margin-bottom: 8px;
  width: 100%;
}

@media screen and (max-width: 820px) {
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    padding: 10px;
    margin-top: 16px;
    row-gap: 0px;
    overflow: auto;
    }

  .date {
    display: flex;
    flex-shrink: 0;
    font: var(--typography-label-subtext);
    margin-bottom: 16px;
  }

  .item {
    border-bottom: 2px solid var(--grey-90);
  }
}
