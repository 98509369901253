.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 100%;
}

.tabs {
  background-color: transparent;
}

.contents {
  flex: 1;
  padding: 24px;
}
