:root {
  --input-min-height: 42px;
  --button-height: 48px;
  --overlay-background-color: rgba(0, 0, 0, 0.9);
  --text-color-accent: #f6f8f9;
  --text-color-secondary: #b2b2b2;
  --typography-h1: 700 normal 24px/30px 'Montserrat', sans-serif;
  --typography-h2: 600 normal 16px/20px 'Montserrat', sans-serif;
  --typography-h3: 600 normal 14px/18px 'Montserrat', sans-serif;

  --typography-body: 400 normal 14px/20px 'Montserrat', sans-serif;

  --typography-body-accent: 600 normal 14px/20px 'Montserrat', sans-serif;
  --typography-body-additional: 400 normal 16px/24px 'Montserrat', sans-serif;
  --typography-error-subtext: 400 normal 12px/16px 'Montserrat', sans-serif;

  --typography-label-subtext: 400 normal 14px/20px 'Montserrat', sans-serif;

  --typography-button: 400 normal 14px/20px 'Montserrat', sans-serif;
  --typography-button-word-mnemonic: 600 normal 14px/18px 'Montserrat', sans-serif;
  --typography-button-large: 600 normal 16px/24px 'Montserrat', sans-serif;
  --toastify-color-dark: rgba(44, 48, 54, 0.95);
  --toastify-toast-width: 480px;
  --toastify-color-progress-dark: rgba(19, 85, 255, 0.9);

  --body-color: var(--grey-0);
  --body-bg-color: var(--grey-95);
  --text-color: var(--grey-40);
  --text-color-accent: var(--grey-10);
  --text-color-secondary: #b2b2b2;
  --dialog-bg-color: var(--grey-85);
  --input-bg-color: var(--grey-85);
  --scroll-color: var(--grey-70);
  --primary-color: var(--accent-blue);
  --primary-dark-color: var(--accent-blue-dark);
  --primary2-color: var(--accent-green);
  --primary2-dark-color: var(--accent-dark-green);
  --secondary-color: #ffffff29; /* ? --grey-80 */
  --secondary-dark-color: #ffffff1f;
  --disabled-color: #4e5561;

  --grey-0: #ffffff;
  --grey-10: #f6f8f9;
  --grey-40: #c9cfd9;
  --grey-50: #9ea6b3;
  --grey-60: #89919e;
  --grey-70: #4b5362;
  --grey-80: #3b424f;
  --grey-85: #2f3542;
  --grey-90: #232933;
  --grey-95: #171d27;
  --grey-100: #000;
  --accent-pale-blue: #71a5f4;
  --accent-blue: #0066ff;
  --accent-green: #00a18c;
  --accent-red: #cc1336;
  --accent-carrot: #fe6713;
  --accent-gold: #c7ae7f;
  --wrapper-background: var(--grey-90);
  --primary-60: #663399;

  --purple-60: #663399;
  --purple-80: #4d2f73;
  --orange: #e88a1b;
  --dark-orange: #c07015;
  --light-grey: #efeeee;
  --dark-grey: #b0aca4;
  --accent-blue-dark: #154185;
  --accent-dark-green: #185951;
  --error-color: #ff6581;

  --alt-bg-color: var(--grey-90);
  --alt-color: var(--grey-70);
  --alt2-color: var(--grey-70);
  --alt3-color: #949494;
  --alt4-color: var(--grey-85);
  --alt5-color: var(--grey-80);
  --alt6-color: var(--purple-60);
  --alt7-color: var(--grey-0);
  --alt8-color: var(--grey-80);
  --alt9-color: var(--grey-85);
  --alt10-color: var(--grey-60);
  --alt11-color: #1e242e;
  --alt12-color: #404040;
  --alt13-color: var(--purple-80);
  --alt14-color: var(--grey-50);
  --alt15-color: var(--grey-50);
  --alt16-color: var(--grey-70);
  --alt17-color: var(--grey-60);
  --alt18-color: var(--grey-85);
  --alt19-color: var(--grey-0);
}

body,
textarea,
input,
label {
  color: var(--text-color);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

button {
  font: var(--typography-button-large);
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background-color: var(--grey-95);
}

::-webkit-scrollbar-thumb {
  background-color: var(--grey-70);
  border-radius: 2px;
}

.label {
  font: var(--typography-h2);
  color: var(--text-color-accent);
}

.subText {
  font: var(--typography-label-subtext);
  color: var(--grey-40);
}

.pageTitle {
  padding: 0 24px;
  margin-top: 8px;
  color: var(--text-color-accent);
  font: var(--typography-h1);
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  width: 100%;
  height: 10%;
}

.content {
  display: flex;
  flex: 1;
}

.routes {
  flex: 1;
  margin-bottom: 24px;
  margin-right: 10px;
}

@media screen and (max-width: 820px) {
  .header {
    display: none;
  }

  .routes {
    flex: 1;
    margin: 0;
  }
}
