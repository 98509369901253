.container {
  height: 20px;
  width: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--grey-40);
  border-radius: 4px;
}

.checked {
  background-color: var(--accent-blue);
}

.container > img {
  height: auto;
  margin: 0;
}