.logo {
  display: flex;
  width: 30px;
  height: 30px;
}

.item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  justify-content: center;
}
