.headerCard {
  font-size: 18px;
  line-height: 24px;
  color: var(--text-color-accent);
  padding-top: 16px;
}

.subHeaderCard {
  color: var(--grey-60);
  font: var(--typography-body);
  margin-bottom: 5px;
}
