.container__nftImg {
  width: 50%;
  height: auto;
}

.container__nftImg img {
  width: 100%;
  height: auto;
}

/* If the screen size is 480px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .container__nftImg {
    display: none;
  }
}
