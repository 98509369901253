.containerCarousel {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  padding: 0 24px;
}

.carousel {
  position: relative;
  width: 100%;
}

.carousel :global(.rec-slider-container) {
  margin: 0;
}

.carousel :global(.rec-dot_active) {
  width: 12px;
  height: 12px;
  background: var(--grey-0) !important;
  box-shadow: 0 0 0 0 var(--grey-0) !important;
}
.carousel :global(.rec-dot) {
  width: 12px;
  height: 12px;
  background: var(--grey-80);
  box-shadow: 0 0 0 0 var(--grey-80);
}

.slideNav:nth-child(1) {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: -10px;
  left: 130px;
}
.slideNav:nth-child(1) > img {
  width: 16px;
  height: auto;
}
.slideNav:nth-child(3) {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: -10px;
  right: 130px;
}
.slideNav:nth-child(3) > img {
  width: 16px;
  height: auto;
}

@media screen and (max-width: 820px) {
  .containerCarousel {
    height: 260px;
    display: flex;
    flex-direction: column;
  }
}
