.container {
  align-items: center;
  display: flex;
  width: 100%;
}

.chain {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.rightIcon {
  height: 24px;
  margin: 0 8px;
  width: 24px;
  cursor: pointer;
  align-self: center;
  transform: rotate(-90deg);
}

.balance {
  color: var(--grey-50);
  font: var(--typography-body);
}

.chainIcon {
  width: 60px;
  display: flex;
  align-items: center;
}

.chainIcon img {
  width: 40px;
}

.chainName {
  color: var(--grey-50);
  font: var(--typography-h2);
}

.name {
  display: flex;
  align-self: center;
}

.iconName {
  display: flex;
}

.containerModal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.titleModal {
  color: var(--grey-50);
  font: var(--typography-body);
  font-weight: 600;
  margin-bottom: 16px;
  text-align: center;
}

.chainListModal {
  margin-bottom: 16px;
}

.chainListItemModal {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 0;
}

.chainListItemModal:not(:last-child) {
  border-bottom: 1px solid var(--grey-70);
}

.chainNameContainerModal {
  display: flex;
  align-items: center;
}

.chainIconModal {
  width: 40px;
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.chainNameModal {
  color: var(--grey-50);
  font: var(--typography-h2);
}

.checkModal {
  height: 20px;
  width: 20px;
}