.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
}

.listFriend {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  column-gap: 50px;
  row-gap: 15px;
}

.listFriend > li {
  flex-shrink: 0;
}

.footer > button {
  width: 100%;
}

.friendList {
  height: 90%;
  overflow-y: scroll;
}

@media screen and (max-width: 820px) {
  .listFriend {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
}
