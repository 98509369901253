.container {
  display: flex;
  align-items: center;
  height: 96px;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  background-color: var(--grey-90);
  flex-shrink: 0;
  margin-bottom: 12px;
}

.thumbnail {
  height: 80px;
  width: 80px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.thumbnail > img {
  object-fit: cover;
  height: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.name {
  margin-bottom: 5px;
  font: var(--typography-h3);
  color: var(--text-color-accent);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.description {
  font: var(--typography-label-subtext);
  color: var(--grey-60);
  margin-bottom: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.image {
  height: 80px !important;
  width: 80px !important;
}
