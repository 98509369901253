.container {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--grey-85);
  margin-top: 16px;
  cursor: pointer;
}

.arrow {
  transform: rotate(-90deg);
  opacity: 0.4;
}

.label {
  color: var(--text-color-accent);
  width: 100%;
}

.label > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
