.modal {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 80vh;
  padding: 24px;
}

.containerHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  gap: 16px;
}

.headerPage {
  color: var(--grey-50);
}

.nameCollectible {
  color: var(--text-color-accent);
  font: var(--typography-h1);
}

.containerHistory {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.button {
  flex-shrink: 0;
  width: 100%;
}
