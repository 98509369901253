.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.titleText {
  font: var(--typography-h2);
  color: var(--grey-40);
  
}

.messageText {
  font: var(--typography-body);
  color: var(--grey-40);
}

.txPrice {
  font: var(--typography-body);
  color: var(--grey-60);
}

.transactionType {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.transactionButton {
  margin: 0.3rem;
}

.cards {
  display: flex;
  width: 100%;
  justify-self: center;
}

.chainList {
  width: 100%;
  margin: 24px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.chainNameContainer {
  display: flex;
  align-items: center;
}

.transactButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.spaceButton {
  margin: 1%;
}