.modal {
  height: 100%;
  width: 100%;
  border-radius: 0;
  padding: 0;
}

.container {
  background-color: var(--grey-95);
  flex: 1;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  text-align: center;
  overflow: hidden;
}

.container__info {
  width: 30%;
  margin-left: 5%;
}

.container__info img {
  display: none;
}

.title {
  color: var(--grey-50);
  font: var(--typography-body-accent);
  font-size: 24px;
  text-align: center;
}

.contents {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.cancelButton {
  width: 100%;
}

/* If the screen size is 820px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .container {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .container__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    height: 100%;
  }

  .container__info img {
    display: flex;
    width: auto;
  }

  .content_img {
    display: flex;
    justify-content: center;
    height: 3rem;
  }

  .title {
    margin-bottom: 24px;
  }
}
