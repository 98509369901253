.container {
  display: flex;
  flex-direction: column;
}

.chips {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}
