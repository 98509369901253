.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrCode {
  border-color: var(--grey-0);
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #fff;
}

.button {
  align-self: stretch;
}
