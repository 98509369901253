.modal {
  height: 80vh;
  width: 100%;
  padding: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
}

.containerHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.header {
  font: var(--typography-h2);
  color: var(--grey-50);
}
