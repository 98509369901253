.wrapper {
  display: flex;
  color: #f6f8f9;
  border-radius: 6px 0px 0px 6px;
}

.clearable {
  flex: 1;
  min-width: 80%;
  border-radius: 6px 0px 0px 6px;
}

.wrapper > label {
  height: var(--input-min-height);
  line-height: var(--input-min-height);
  background: #4b5362;
  color: #949494;
  font-weight: 500;
  font-size: 14px;
  padding: 0 15px;
  cursor: pointer;
  border-radius: 0px 6px 6px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper > label:hover {
  background: #0066ff;
  color: white;
}

.wrapper > label > input {
  display: none;
}
