.container {
  display: flex;
  flex-direction: column;
  color: var(--text-color);
}

.lockArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  align-items: flex-start;
  margin-top: 1rem;
  color: #71a5f4;
}

.lockArea img {
  display: none;
}

@media screen and (max-width: 820px) {
  .lockArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--grey-85);
    border: 1px solid var(--grey-85);
    border-radius: 6px;
    padding: 8px;
    margin-top: 0;
    color: inherit;
    cursor: pointer;
  }

  .lockArea img {
    display: flex;
  }
}
