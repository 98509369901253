.container {
  align-items: center;
  display: flex;
}

.chain {
  align-items: center;
  display: flex;
}

.downIcon {
  height: 16px;
  margin: 0 8px;
  width: 16px;
}

.balance {
  color: var(--grey-50);
  font: var(--typography-body);
}

.list {
  display: flex;
  flex-direction: column;
}