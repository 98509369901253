.container {
  background-color: var(--grey-95);
  flex: 1;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  text-align: center;
  overflow: hidden;
}

.container__info {
  align-self: flex-start;
  float: left;
  width: 30%;
  margin-left: 5%;
  margin-top: 10%;
}

.header {
  padding: 24px 0;
}

.title__mobile {
  display: none;
}

.title__desktop {
  text-align: left;
  color: var(--grey-10);
  font: var(--typography-h1);
  line-height: 30px;
  margin: 24px 0;
  font-size: 3vh;
}

.description {
  height: 42px;
  font: var(--typography-body);
  color: var(--grey-40);
  text-align: left;
}

.description__confirm {
  display: none;
}

.description__confirm__desktop {
  height: 42px;
  font: var(--typography-body);
  color: var(--grey-40);
  text-align: left;
}

.content {
  align-items: center;
  flex: 1;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}

.content__previousPin {
  align-items: center;
  flex: 1;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  pointer-events: none;
}

/* If the screen size is 820px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .container {
    flex-direction: column;
  }

  .header {
    padding: 24px;
  }

  .container__info {
    width: 100%;
    margin: 0;
    height: 100%;
  }

  .title__mobile {
    display: block;
    color: var(--grey-10) !important;
    font: var(--typography-h1) !important;
    line-height: 30px !important;
    margin: 24px 0 !important;
  }

  .title__desktop {
    display: none;
  }

  .content {
    margin: 16px 0;
  }

  .content__previousPin {
    display: none;
  }

  .description {
    display: none;
  }

  .description__confirm__desktop {
    display: none;
  }

  .description__confirm {
    display: inline;
    text-align: center;
  }
}
