.containerWallet {
  display: none;
}

.containerDesktop {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.containerHeader {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}

.containerContent {
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  padding-bottom: 16px;
}

.containerInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 500px;
  overflow-y: scroll;
  padding: 0 24px;
}

.containerField {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.containerFooter {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 500px;
  padding: 1rem;
  flex-shrink: 0;
}

.link {
  color: var(--accent-blue);
  text-decoration: underline;
  font: var(--typography-label-subtext);
}

.nftInfo {
  display: flex;
  width: 100%;
  min-height: 60%;
  flex-direction: row;
  gap: 10rem;
}

.containerIpfs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
}

.description {
  display: flex;
  flex-direction: column;
  height: 20%;
  align-content: flex-start;
}

.ownership {
  display: flex;
  flex-direction: column;
  height: 60%;
  gap: 30px;
}

.errorLoading {
  display: flex;
  flex-direction: column;
  padding: 15rem;
  text-align: justify;
}

@media screen and (max-width: 820px) {
  .containerWallet {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .containerHeader {
    padding: 0;
  }

  .containerDesktop {
    display: none;
  }

  .containerContent {
    gap: 16px;
  }

  .containerFooter {
    display: flex;
    flex-direction: column;
    width: auto;
    gap: 16px;
    flex-shrink: 0;
    padding: 0;
  }

  .containerInfo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    width: auto;
    overflow-y: scroll;
    padding: 0 24px;
  }

  .errorLoading {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    text-align: justify;
    margin-top: 3rem;
  }
}
