.container {
    display: flex;
    height: 36px;
    width: 100%;
    margin-top: 14px;
  }
  
  .container li {
    flex-grow: 1;
  }
  
  .tab {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    color: var(--grey-60);
  }
  
  .tab:hover,
  .tab:active,
  .active {
    color: var(--text-color-accent);
    font-weight: 600;
  }
  
  .active {
    border-bottom: 4px solid var(--accent-blue);
  }
  
  @media screen and (max-width: 820px) {
    .container {
      background-color: var(--grey-90);
      width: 100%;
    }
  
    .tab {
      border-bottom: 2px solid var(--grey-70);
    }
  }
  