.words {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.word {
  border: 0;
  padding: 4px 8px;
  background: var(--grey-85);
  border-radius: 6px;
  color: var(--text-color-accent);
  font: var(--typography-button-word-mnemonic);
}

.word:disabled {
  color: var(--grey-85);
}
