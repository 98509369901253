.container > dl {
  display: flex;
  justify-content: space-between;
}

.container > dl:nth-child(1) {
  margin-bottom: 8px;
}

.container > dl:nth-child(2) {
  margin-bottom: 12px;
}

.container > dl:last-child {
  border-top: 2px solid var(--grey-85);
  padding-top: 12px;
}

.container > dl > dt {
  color: var(--grey-50);
  font: var(--typography-body);
}

.container > dl > dd {
  color: var(--grey-0);
  font: var(--typography-body);
  font-weight: 600;
}
