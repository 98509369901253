.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
}

.containerHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.header {
  font: var(--typography-h2);
  color: var(--grey-50);
}

.list {
  list-style-type: none;
}

.list > li {
  display: flex;
  justify-content: space-between;
}

.list > li > div {
  display: flex;
}

.list > li:not(:last-child) {
  margin-bottom: 16px;
}

.coinName {
  display: inline-block;
  margin-left: 12px;
}

@media screen and (max-width: 820px) {
  .model {
    width: 100%;
  }
}