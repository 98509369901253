.container {
  position: absolute;
}

.text {
  text-align: center;
}

@media screen and (max-width: 820px) {
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;
  }

  .text {
    text-align: center;
  }
}
