.chip {
  border-radius: 4px;
  font: var(--typography-body-accent);
  padding: 3px 8px;
}

.chip.none {
  background-color: var(--grey-90);
  color: var(--grey-40);
}

.chip.warning {
  background-color: rgba(254, 103, 19, 0.15);
  color: var(--accent-carrot);
}

.chip.error {
  background-color: rgba(204, 19, 54, 0.15);
  color: var(--accent-red);
}
