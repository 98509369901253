.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 24px;
}

.listFriend {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  column-gap: 50px;
  row-gap: 15px;
  max-height: 550px;
  overflow-y: scroll;
}

.listFriend > li {
  flex-shrink: 0;
  position: relative;
}

.footer > button {
  width: 100%;
}

.topBar {
  display: flex;
  flex-direction: row;
}

.recipientType {
  display: flex;
  width: 30%;
  margin-bottom: 0.5rem;
}

.searchBar {
  display: flex;
  width: auto;
  margin-bottom: 0.5rem;
}

.containerButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
}

.selectedElement {
  background-color: #0066ff;
  border-radius: 8px;
}

.ulContainer {
  overflow: hidden;
}

.recipientAddress {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;
  gap: 8px;
}

.mainItems {
  display: flex;
  flex-direction: column;
  height: 90vh;
  margin-top: 1rem;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 10vh;
  margin-top: auto;
  padding-right: 3rem;
}

.cancelButton {
  display: flex;
  width: auto;
}

.continueButton {
  display: flex;
  width: auto;
}

.previousButton {
  display: flex;
  width: auto;
  margin-right: 10px;
}
