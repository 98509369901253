.container {
  display: inline-block;
  font: var(--typography-body);
  vertical-align: middle;
}

.skeleton {
  display: inline-block;
  width: 100px;
}
