.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.contents {
  flex: 1;
  padding: 24px;
}

.subtitle {
  color: var(--grey-10);
  font: var(--typography-h2);
  margin-bottom: 16px;
}

.description {
  color: var(--grey-40);
  font: var(--typography-body);
  margin-bottom: 24px;
}

.userInfo {
  margin-bottom: 16px;
}

.footer {
  padding: 24px;
}

.okButton {
  width: 100%;
}
