
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  color: var(--text-color-accent);
}

.container > button {
  align-self: flex-end;
}
