.button {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  text-align: left;

  border: 0;
  padding: 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: inherit;
  background-color: inherit;

  transition: 0.2s ease-in-out;

  cursor: pointer;
}

.button:disabled {
  cursor: default;
}
