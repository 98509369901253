.container {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  background-color: var(--grey-90);
  border-radius: 8px;
}

.item {
  padding: 4px;
  width: 50%;
  height: 100%;
}

.itemContents {
  height: 100%;
  width: 100%;
  text-align: center;
}

.itemContentsSelected {
  border-radius: 6px;
  background-color: var(--accent-blue);
}

.itemText {
  color: var(--text-color-accent);
}

@media screen and (max-width: 820px) {
  .container {
    width: auto;
  }
}
