.input {
  border-bottom: 4px solid var(--grey-85);
  color: #fff;
  font: var(--typography-h1);
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 56px;
  background-color: var(--grey-85);
  border-radius: 8px;
}

.error {
  border-color: var(--accent-red);
  color: var(--accent-red);
}

@media screen and (max-width: 820px) {
  .input {
    border-bottom: 4px solid var(--grey-85);
    background-color: transparent;
    border-radius: 0px;
    height: auto;
  }
}
