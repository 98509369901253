.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.containerHeader {
  display: none;
}

.header {
  padding-top: 32px;
  padding-left: 26px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  overflow-y: scroll;
  padding-left: 26px;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.footer {
  display: none;
}

.button {
  margin: 16px 24px 16px 24px;
}

@media screen and (max-width: 820px) {
  .footer {
    display: flex;
    flex-shrink: 0;
  }

  .header {
    display: none;
  }

  .containerHeader {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background-color: var(--grey-90);
    padding-bottom: 8px;
  }

  .contentContainer {
    height: 100%;
    padding-left: 0px;
  }
}
