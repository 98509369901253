.wrapper {
  min-width: 254px;
  height: 100%;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
}

.tradeButton {
  padding: 0 25px;
}

.profileSelected {
  border: 2px solid #0066ff !important;
}

.create,
.profile {
  --margin: 20px;
  margin: 0 var(--margin);
  width: calc(100% - var(--margin) * 2);
}

.profile {
  border: 2px solid var(--grey-70) !important;
  border-radius: 6px !important;
  padding: 10px !important;
  color: var(--text-color) !important;
}

.profile:disabled {
  color: var(--text-color-accent);
  border: 2px solid var(--primary-color);
  opacity: 1;
}

.profile > figure {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.profile > div {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
}

.profile > svg {
  flex-shrink: 0;
  transform: rotate(-90deg);
}

.listMenu li {
  padding-left: 40px;
  color: var(--text-color);
  font-size: 14px;
  line-height: 52px;
}

.listMenu li.active,
.listMenu li:hover {
  color: var(--text-color-accent);
}

.listMenu li.active {
  border-left: 4px solid;
  border-color: var(--primary-color);
}

.copyright {
  padding-left: 40px;
  margin-top: auto;
}

.copyright > span {
  margin-left: 10px;
}

/* If the screen size is 820px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .wrapper {
    display: none;
  }
}
