.dots {
  display: flex;
  justify-content: left;
  padding: 24px 0;
}

.dotContainer {
  padding: 20px;
  background-color: #2f3542;
  border-radius: 5px;
  margin: 5px;
}

.dot {
  background-color: var(--grey-60);
  border-radius: 8px;
  height: 10px;
  width: 10px;
}

.dotActive {
  background-color: var(--accent-blue);
}

.keyboard {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-width: 270px;
  max-width: 320px;
}

.keyboardKeys {
  display: none;
}

.keyboardKey {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 30%;
}

.digit {
  color: var(--grey-0);
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 24px;
}

/* If the screen size is 480px wide or less, hide text and nftLogo */
@media screen and (max-width: 820px) {
  .dots {
    justify-content: center;
    padding: 0;
    margin-bottom: 36px;
  }

  .dotContainer {
    background-color: transparent;
    border-radius: 0px;
    margin: 0px;
  }

  .keyboardKeys {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .keyboardKey > img {
    height: 40px;
  }
}
